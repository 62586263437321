import { Component, OnInit } from '@angular/core';
import data from '../../assets/json/blog-posts.json';
@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {

  articles = data;

  constructor() { }

  ngOnInit(): void {
    console.log(this.articles);

  }
}

