<section id="footer">
  <!-- WAVE SVG -->
  <div class="relative">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#f8f8f8" fill-opacity="1" d="M0,32L48,37.3C96,43,192,53,288,80C384,107,480,149,576,160C672,171,768,149,864,133.3C960,117,1056,107,1152,117.3C1248,128,1344,160,1392,176L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
    </svg>
  </div>
  <div class="pb-28 bg-adtomic_main_gray">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="text-base text-adtomic_main_purple font-semibold tracking-wide uppercase">Make it happen</h2>
        <p class="font-ubuntu mt-2 mb-12 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Ready to lift off?
        </p>
      </div>
      <div class="sm:w-6/12 sm:mx-auto">
        <form class="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" value="true">
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="email-address" class="sr-only">Your email</label>
              <input id="email-address" name="email" type="email" autocomplete="email" required
                class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-adtomic_main_purple focus:z-10 sm:text-sm"
                placeholder="Your email">
            </div>
          </div>
          <div class="mt-1">
            <button type="submit"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-adtomic_main_purple hover:bg-adtomic_secondary_purple_normal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mb-8">
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              </span>
                  Get early access
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- LOGO & COPYRIGHT -->
  <div class=" bg-adtomic_main_purple py-8">
    <div class="container mx-auto px-4">
      <div class="flex-1 flex items-center justify-center">
        <!-- Logo -->
        <div class="flex-shrink-0 flex items-center">
          <img class="block h-16 w-28" src="assets/logos/dotbase_logo_V1_white.svg" alt="dotter.ai">
          <!-- <h1 class="font-ubuntu font-bold text-adtomic_main_white text-2xl">⚡ dotter.ai</h1> -->
          <span class="px-3 text-white">|</span><p class="text-white"> © dotbase.io 2021</p>
        </div>
      </div>
    </div>
  </div>

</section>
