<!-- BLOG -->
  <div class="container max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
    <div class="lg:text-center my-12">
      <h2 class="text-base text-adtomic_main_purple font-semibold tracking-wide uppercase">insights & more</h2>
      <p
        class="font-ubuntu mt-2 mb-12 text-3xl leading-8 font-extrabold tracking-tight text-adtomic_neutral_2 sm:text-4xl">
        Our blog
      </p>
      <!-- <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
        Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
        accusamus quisquam.
      </p> -->
    </div>
    <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
      <!-- BLOG POST -->
        <div *ngFor="let article of articles">
        <a routerLink="/blog-post" routerLinkActive="router-link-active" >
          <article class="overflow-hidden rounded-lg shadow-lg cursor-pointer hover:bg-adtomic_main_gray">

            <img alt="Placeholder" class="block h-auto w-full" src="https://loremflickr.com/600/400/">
            <div class="flex items-center justify-between leading-tight p-2 md:p-4">
              <h1 class="font-ubuntu text-2xl text-adtomic_main_purple">{{article.title}}
              </h1>
            </div>
            <div class="p-2 md:p-4">
              <p class="text-adtomic_neutral_2 text-preview-card">{{article.body}}</p>
            </div>
            <div class="flex items-start justify-start leading-none p-2 md:p-4 mt-4 font-bold">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="20px">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              <p class="text-grey-darker text-sm text-adtomic_neutral_2 ml-2">
                {{article.date}}
              </p>
            </div>
          </article>
        </a>
      </div>
    </div>
  </div>
