<section id="header">
  <div class="container max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
      <div class="mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-0 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h1 class="font-ubuntu tracking-tight font-extrabold text-adtomic_neutral_2 text-3xl text-center sm:text-left sm:text-4xl">
            <span class="block xl:inline">Empower your business to</span>
            <span class="block text-adtomic_main_purple xl:inline"> maximize its potential</span>
          </h1>
          <p class="mt-3 text-base text-center sm:text-left text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Open new avenues of growth and transformation with technology and insights at scale.
          </p>
          <!-- <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-adtomic_main_purple hover:bg-adtomic_main_purple-700 md:py-4 md:text-lg md:px-10">
                Get early access
              </a>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-3">
              <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                Live demo
              </a>
            </div>
          </div> -->
          <form class="mt-8 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" value="true">
            <div class="rounded-md shadow-sm -space-y-px">
              <div>
                <label for="email-address" class="sr-only">Your email</label>
                <input id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-adtomic_main_purple focus:z-10 sm:text-sm" placeholder="Your email">
              </div>

            </div>

            <div class="mt-1">
              <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-adtomic_main_purple hover:bg-adtomic_secondary_purple_normal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Get early access
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-span-2 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-10 order-first lg:order-last">
        <embed src="assets/illustrations/statistics_animate.svg" type="" class="illustration-animated">
      </div>
    </div>
  </div>
  <!-- ARROW DOWN -->
  <svg class="flex content-center mx-auto animate-bounce w-6 h-6 mt-14" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#744fdc">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
  </svg>
  <!-- WAVE BACKGROUND SVG -->
  <div class="relative">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#f8f8f8" fill-opacity="1" d="M0,32L48,37.3C96,43,192,53,288,80C384,107,480,149,576,160C672,171,768,149,864,133.3C960,117,1056,107,1152,117.3C1248,128,1344,160,1392,176L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
    </svg>
  </div>
</section>



