<nav class="bg-adtomic_main_white container mx-auto px-4">
  <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-2">
    <div class="relative flex items-center justify-between h-16">
      <!-- Navbar items -->
      <div class="flex-1 flex items-center justify-start sm:items-stretch sm:justify-start">
        <!-- Logo -->
        <a routerLink="/" routerLinkActive="router-link-active" >
          <div class="flex-shrink-0 flex items-center">
            <img class="block h-16 w-32" src="assets/logos/dotbase_logo_V1.svg" alt="dotter.ai">
            <!-- <h1 class="font-ubuntu font-bold text-adtomic_main_purple text-2xl">⚡ dotter.ai</h1> -->
          </div>
        </a>
      </div>
      <!-- <a [routerLink]="['/blog']" routerLinkActive="router-link-active" >
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <button class="flex bg-adtomic_main_white p-1 rounded-full text-adtomic_main_purple hover:text-adtomic_secondary_purple_normal focus:outline-none">

            Heroicon name: doc
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <span class="font-ubuntu text-base hidden md:block">Blog</span>
          </button>

        </div>
      </a> -->

    </div>
  </div>

</nav>
