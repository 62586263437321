<section id="body">
  <!-- EMPOWER -->
  <div class="py-12 bg-gradient-to-b from-adtomic_main_gray to-adtomic_main_white">
    <div class="container max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="text-base text-adtomic_main_purple font-semibold tracking-wide uppercase">Maximize growth</h2>
        <p
          class="font-ubuntu mt-2 mb-12 text-3xl leading-8 font-extrabold tracking-tight text-adtomic_neutral_2 sm:text-4xl">
          Step up and reimagine your marketing strategy
        </p>
        <!-- <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.
        </p> -->
      </div>
      <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center h-12 w-12 rounded-full bg-adtomic_main_purple text-white">
                <!-- Heroicon name: presentation-line-chart -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24"
                  stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                </svg>
              </div>
            </div>
            <div class="ml-4">
              <dt class="font-ubuntu text-lg leading-6 font-medium text-adtomic_neutral_2">
                Profit Bidding
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Optimize your media spend against channel specific profit. Capture the customers that matter the most to your business and make budget allocation decisions based on ROI.
              </dd>
            </div>
          </div>
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center h-12 w-12 rounded-full bg-adtomic_main_purple text-white">
                <!-- Heroicon name: briefcase -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24"
                  stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              </div>
            </div>
            <div class="ml-4">
              <dt class="font-ubuntu text-lg leading-6 font-medium text-adtomic_neutral_2">
                Business results
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Measure your full funnel marketing strategy by deduplicating conversions across channels and linking KPIs. Attribute conversions to understand how different customer touch points interact with each other to drive business results.
              </dd>
            </div>
          </div>
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center h-12 w-12 rounded-full bg-adtomic_main_purple text-white">
                <!-- Heroicon name: outline/lightning-bolt -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24"
                  stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
            </div>
            <div class="ml-4">
              <dt class="font-ubuntu text-lg leading-6 font-medium text-adtomic_neutral_2">
                Actionable insights
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Know your customer. Leverage Big Query to build and deploy state-of-the-art machine learning models on your structured data to analyze insights and make sound business decisions.
              </dd>
            </div>
          </div>
          <div class="flex">
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center h-12 w-12 rounded-full bg-adtomic_main_purple text-white">
                <!-- Heroicon name: desktop-computer -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24"
                  stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              </div>
            </div>
            <div class="ml-4">
              <dt class="font-ubuntu text-lg leading-6 font-medium text-adtomic_neutral_2">
                Technology partner
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Dotter is focused on delivering technology that helps your business grow. Keep your business up to date with the latest trends and technologies by implementing our array of one click solutions.
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
  <!-- HOW IT WORKS -->
  <div class="py-32 bg-gradient-to-b from-adtomic_white to-adtomic_main_gray">
    <div class="container max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 ">
      <div class="lg:text-center">
        <h2 class="text-base text-adtomic_main_purple font-semibold tracking-wide uppercase">Let the magic happen</h2>
        <p
          class="font-ubuntu mt-2 mb-12 text-3xl leading-8 font-extrabold tracking-tight text-adtomic_neutral_2 sm:text-4xl">
          How it works
        </p>
        <!-- <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.
        </p> -->
      </div>
      <div class="mt-10">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10">
            <div class="flex">
              <!-- data-aos-duration="500" data-aos-easing="ease-in-sine" -->
              <div class="flex-shrink-0">
                <div class="flex items-center justify-center h-14 w-14 rounded-md bg-white text-adtomic_main_purple shadow-2xl">
                  <!-- Heroicon name: database -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="font-ubuntu text-lg leading-6 font-medium text-adtomic_neutral_2">
                  Data Collection
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Connect and integrate your data. Free yourself from platform silos by creating your own data lake and bringing it to a data warehouse.
                </dd>
              </div>
            </div>
            <div class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex items-center justify-center h-14 w-14 rounded-md bg-white text-adtomic_main_purple shadow-2xl">
                  <!-- Heroicon name: cube-transparent -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="font-ubuntu text-lg leading-6 font-medium text-adtomic_neutral_2">
                  Process data
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Use state-of-the-art machine learning models to tackle mission-critical tasks. Leverage your structured data to train models and make decisions at speed and scale.
                </dd>
              </div>
            </div>
            <div class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex items-center justify-center h-14 w-14 rounded-md bg-white text-adtomic_main_purple shadow-2xl">
                  <!-- Heroicon name: outline/lightning-bolt -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="font-ubuntu text-lg leading-6 font-medium text-adtomic_neutral_2">
                  Visualize Insights
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Marketing should be all about your customers. Transform your business by empowering your team with actionable insights based on user behaviour.
                </dd>
              </div>
            </div>
            <div class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex items-center justify-center h-14 w-14 rounded-md bg-white text-adtomic_main_purple shadow-2xl">
                  <!-- Heroicon name: chart-square-bar -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="font-ubuntu text-lg leading-6 font-medium text-adtomic_neutral_2">
                  Make data-driven decisions
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Save time and money by understanding each channel's ROI while maximizing your revenue and optimizing your media marketing mix.
                </dd>
              </div>
            </div>
          </dl>
          <!-- ILLUSTRATION -->
          <div
            class="sm:mt-10 mx-auto max-w-7xl px-4 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-10 order-first lg:order-last">
            <!-- <img src="assets/illustrations/market_launch.png" width="800px" alt=""> -->
            <img src="assets/screens/3.png" width="800px" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- BLOG POSTS-->
  <!-- <div class="container max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
    <div class="lg:text-center my-12">
      <h2 class="text-base text-adtomic_main_purple font-semibold tracking-wide uppercase">insights & more</h2>
      <p
        class="font-ubuntu mt-2 mb-12 text-3xl leading-8 font-extrabold tracking-tight text-adtomic_neutral_2 sm:text-4xl">
        Our blog
      </p>
      <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
        Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
        accusamus quisquam.
      </p>
    </div>
    <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">

        <div *ngFor="let article of articles">
        <a routerLink="/blog-post" routerLinkActive="router-link-active" >
          <article class="overflow-hidden rounded-lg shadow-lg cursor-pointer hover:bg-adtomic_main_gray">

            <img alt="Placeholder" class="block h-auto w-full" src="https://loremflickr.com/600/400/">
            <div class="flex items-center justify-between leading-tight p-2 md:p-4">
              <h1 class="font-ubuntu text-2xl text-adtomic_main_purple">{{article.title}}
              </h1>
            </div>
            <div class="p-2 md:p-4">
              <p class="text-adtomic_neutral_2 text-preview-card">{{article.body}}</p>
            </div>
            <div class="flex items-start justify-start leading-none p-2 md:p-4 mt-4 font-bold">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="20px">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              <p class="text-grey-darker text-sm text-adtomic_neutral_2 ml-2">
                {{article.date}}
              </p>
            </div>
          </article>
        </a>
      </div>
    </div>
  </div> -->
</section>
